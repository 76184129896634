.component {
    display: flex;
    align-items: stretch;
    border-top: 1px solid #ccc;

    i {
        font-size: 18px;
    }

    div:first-child {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #54554F;
    }

    div:last-child {
        padding: 10px;
        flex-basis: 100%; /* width/height  - initial value: auto */
        h4 {
            color: #C01C2B;
            margin-bottom: 10px;
        }
    }

    a{
        color:#353535;
    }
}
