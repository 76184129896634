@import url("../shared/colors.less");

.component {
    text-decoration: none;
    color: @black;
    border: 1px solid #252525;
    border-radius: 10px;
    padding: 10px;
    background-color: #EAD3A9;
    margin: 10px 0;
    display: block;

    p {
        margin-bottom: 5px;
    }

    hr {
        border: none;
        height: 1px;
        background-color: #252525;
        margin: 7px 0;
    }

    > div {
        display: flex;
        justify-content: space-between;

        span {
            color: white;
            padding: 2px 5px;
            border-radius: 4px;

            &.waiting {
                background-color: #F2A415;
            }

            &.completed {
                background-color: #28A351;
            }

            &.all {
                background-color: #3b7bb9;
            }

            &.delivery {
                background-color: #2AA389;
            }

            &.processing {
                background-color: #F08948;
            }

            &.canceled {
                background-color: #BE1E2B;
            }
        }
    }
}

