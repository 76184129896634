@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?72864838');
  src: url('../font/fontello.eot?72864838#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?72864838') format('woff2'),
       url('../font/fontello.woff?72864838') format('woff'),
       url('../font/fontello.ttf?72864838') format('truetype'),
       url('../font/fontello.svg?72864838#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?72864838#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-exchange:before { content: '\e800'; } /* '' */
.icon-down-open:before { content: '\e801'; } /* '' */
.icon-left-open:before { content: '\e802'; } /* '' */
.icon-cw:before { content: '\e803'; } /* '' */
.icon-arrows-ccw:before { content: '\e804'; } /* '' */
.icon-cancel:before { content: '\e805'; } /* '' */
.icon-cog-alt:before { content: '\e806'; } /* '' */
.icon-check:before { content: '\e807'; } /* '' */
.icon-pencil:before { content: '\e808'; } /* '' */
.icon-eye:before { content: '\e809'; } /* '' */
.icon-trash:before { content: '\e80a'; } /* '' */
.icon-check-1:before { content: '\e80b'; } /* '' */
.icon-print:before { content: '\e80c'; } /* '' */
.icon-home:before { content: '\e80d'; } /* '' */
.icon-book:before { content: '\e80e'; } /* '' */
.icon-calendar:before { content: '\e80f'; } /* '' */
.icon-basket:before { content: '\e810'; } /* '' */
.icon-users:before { content: '\e811'; } /* '' */
.icon-user:before { content: '\e812'; } /* '' */
.icon-picture:before { content: '\e813'; } /* '' */
.icon-location:before { content: '\e814'; } /* '' */
.icon-info:before { content: '\e815'; } /* '' */
.icon-bell:before { content: '\e816'; } /* '' */
.icon-sort-alphabet-outline:before { content: '\e817'; } /* '' */
.icon-wallet:before { content: '\e818'; } /* '' */
.icon-food:before { content: '\e819'; } /* '' */
.icon-hash:before { content: '\e81a'; } /* '' */
.icon-basket-1:before { content: '\e81b'; } /* '' */
.icon-phone:before { content: '\e81c'; } /* '' */
.icon-ok-circled2:before { content: '\e81d'; } /* '' */
.icon-signal-1:before { content: '\e81e'; } /* '' */
.icon-right-open:before { content: '\e81f'; } /* '' */
.icon-up-open:before { content: '\e820'; } /* '' */
.icon-check-2:before { content: '\e821'; } /* '' */
.icon-location-1:before { content: '\e822'; } /* '' */
.icon-truck:before { content: '\e823'; } /* '' */
.icon-money:before { content: '\e824'; } /* '' */
.icon-layout:before { content: '\e825'; } /* '' */
.icon-logout:before { content: '\e826'; } /* '' */
.icon-mail:before { content: '\e827'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-spin5:before { content: '\e838'; } /* '' */
.icon-spin6:before { content: '\e839'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-rouble:before { content: '\f158'; } /* '' */
.icon-sort-name-down:before { content: '\f15e'; } /* '' */
.icon-sort-alt-up:before { content: '\f160'; } /* '' */
.icon-sort-alt-down:before { content: '\f161'; } /* '' */
.icon-sort-number-up:before { content: '\f162'; } /* '' */
.icon-sort-number-down:before { content: '\f163'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-down:before { content: '\f175'; } /* '' */
.icon-up:before { content: '\f176'; } /* '' */
.icon-box:before { content: '\f187'; } /* '' */
.icon-vkontakte:before { content: '\f189'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-calc:before { content: '\f1ec'; } /* '' */
.icon-map-signs:before { content: '\f277'; } /* '' */
.icon-commenting-o:before { content: '\f27b'; } /* '' */
.icon-address-card-o:before { content: '\f2bc'; } /* '' */
.icon-facebook:before { content: '\f30c'; } /* '' */
