@import url(../../shared/colors.less);

.line {
    display:block;
    color:@black;
    text-decoration:none;
    margin-bottom: 15px;
    border: 1px solid @mainColorDark;
    border-radius: 5px;
    padding: 10px 15px;
    background-color: @mainColorMedium;

    cursor:pointer;

    > div {
        margin-bottom: 5px;
    }

    a {
        color: @black;
    }
}
