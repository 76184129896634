@import url(../../shared/colors.less);

.line {
    margin-bottom: 15px;
    border: 1px solid @mainColorDark;
    border-radius: 5px;
    padding: 10px 15px;
    background-color: @mainColorMedium;
    font-size: 18px;
}

.input{
    border:none;
    padding:5px;
    background-color:transparent;
    border-bottom:1px solid @black;
    font-size:18px;

    &[type=number]{
        width:100px;    
    }
}