@import url("../shared/colors.less");

.component {
    min-height: 100px;
    background-color: @mainColorMedium;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    border-bottom: 1px solid @mainColorDark;

    .logoOms {
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
            border-radius: 50%;
            background-size: cover;
            width: 40px;
            height: 40px;
            background-image: url("../../images/oms-logo.jpg");
        }

        p {
            color: @black;
            font-weight: bold;
            margin-left: 5px;
            width: 50%;
            text-align: center;
        }
    }

    .bottomSection {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .goBack {
            display: flex;
            align-items: center;
            position: absolute;
            left: 0;
            padding: 5px 15px 5px 0;

            i {
                font-size: 20px;
                color: @black;
            }
        }

        .logout {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            padding: 5px 15px 5px 0;

            i {
                font-size: 20px;
                color: @black;
            }
        }

        > span {
            font-weight: bold;
            text-align: center;
            color: @black;
        }
    }
}
