@import url(../../components/shared/colors.less);

.button {
    border: none;
    padding:10px 20px;
    margin:10px 0;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;

    &[data-mode = full]{
        width:100%;
    }

    &[data-bg = red] {
        background-color: @color-red;

        &:hover {
            background-color: @color-red-dark;
        }
    }

    &[data-bg = green] {
        background-color: #429A16;

        &:hover {
            background-color: #2F6E10;
        }
    }

    &[data-bg = blue] {
        background-color: #0066bd;

        &:hover {
            background-color: #05579d;
        }
    }


    &.--red {
    }

    &.--red:hover {
    }

    &.--blue {
        background-color: #0066bd;
    }

    &.--blue:hover {
        background-color: #05579d;
    }

    &.--green {
        background-color: #429A16;
    }

    &.--green:hover {
        background-color: #2F6E10;
    }
}
