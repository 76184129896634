@import url(../shared/colors.less);

.formLine {
    margin: 15px 0;
}


.textarea {
    border: 1px solid @mainColorDark;
    width: 100%;
    resize: none;
    height: 150px;
    padding: 10px;
    font-size: 18px;
    background-color: #eeddbf;

    &:focus {
        outline: none;
    }
}

.disabled {
    pointer-events: none;
    /*filter: blur(4px);*/
    opacity:0.3;
}