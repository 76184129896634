.caption{
    padding:15px 10px;
}

.bluredBlock {
    filter: blur(4px);
}

.itemBlock {
    > div:first-child {
        display: flex;
        align-items: stretch;
        margin: 10px 0;

        div:first-child {
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: #54554F;

            img {
                max-width: 80%;
            }
        }

        div:last-child {
            padding: 10px;
            flex-basis: 100%; /* width/height  - initial value: auto */
            h4 {
                color: #C01C2B;
                margin-bottom: 10px;
            }
        }
    }

    > div:last-child {
        text-align: center;
        padding: 10px;
    }
}

.button {
    width: 80%;
    border: 1px solid #555;
    background-color: #F5DEB4;
    margin: 10px auto;
    position: relative;
    padding: 12px 20px;
    font-size: 22px;
    display: block;

    &.--red {
        background-color: #EB4C42;
    }
}

.summary {
    background-color: #F5DEB4;
    padding: 20px;
    border-bottom: 1px solid #ccc;

    h4 {
        text-align: center;
        font-size: 28px;
        margin: 5px 0;
    }

    p {
        font-size: 16px;
        margin: 7px 0;
    }
}

.button {
    width: 80%;
    border: 1px solid #555;
    background-color: #F5DEB4;
    margin: 10px auto;
    position: relative;
    padding: 12px 20px;
    font-size: 22px;
    display: block;

    &.red {
        background-color: #EB4C42;
    }
}

#statusPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;

    .completed {
        background-color: #28A351;
    }

    .processing {
        background-color: #F08948;
    }

    .canceled {
        background-color: #BE1E2B;
    }
}

.captionBlock {
    width: 100%;
    position: fixed;
    background-color: #F5DEB4;
    padding: 10px 5px;

    button {
        padding: 5px 10px;
        font-size: 20px;
        border: none;
        background-color: transparent;
    }

    margin-bottom: 10px;
}


#page-order-details h3 {
    padding-top: 30px;
}


.noData {
    color: #4e4b4b;
    font-size: 26px;
    text-align: center;
    padding: 20px;
    height: 80vh;
    display: flex;
    align-items: center;

    > div {
        border: 1px solid #4e4b4b;
        margin: 20px auto;
        rotate: -45deg;
        padding: 20px;
    }
}