@import url("../shared/colors.less");

.component {
    text-decoration:none;
    color: @black;
    border-radius: 5px;
    margin: 1%;
    width: 31.33333%;
    height: 120px;
    background-color: #EBD4AA;
    border: 1px solid #d7b881;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
        background-color: #e5ba6a;
    }

    i {
        font-size: 38px;
        color: #54554E;
    }

    h4 {
        position: absolute;
        left: 5px;
        bottom: 5px;
        text-align: left;
        font-size: 14px;
        font-weight: normal;
    }
}
