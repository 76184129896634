#pageOrder {
    padding: 15px 0;
}

.waiting {
    background-color: #F2A415;
}

.completed {
    background-color: #28A351;
}

.all {
    background-color: #3b7bb9;
}

.delivery {
    background-color: #2AA389;
}

.processing {
    background-color: #F08948;
}

.canceled {
    background-color: #BE1E2B;
}

.orderStatusItem:not(.active) {
    filter: grayscale(1);
}

.orderStatuses {
    width: 100%;
    overflow-x: scroll;

    > div {
        width:800px;
        div {
            text-align:center;
            width:140px;
            border-radius: 5px;
            padding: 5px;
            display: inline-block;
            margin: 0 10px;
            color: #fff;
        }
    }
}