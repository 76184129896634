@import url(../../shared/colors.less);

.component {
    text-decoration:none;
    display: flex;
    color: @black;
    text-decoration: none;
    margin-bottom: 15px;
    border: 1px solid @mainColorDark;
    border-radius: 5px;
    padding: 10px 15px;
    background-color: @mainColorMedium;
    cursor: pointer;
    hr{
        border:1px solid @black;
        margin:5px 0;
    }
    >div:last-child{
        display:flex;
        flex-direction:column;
        >div{
            flex-grow:1;
        }
    }
}


.image{
    background-color:#fff;
    overflow:hidden;
    border-radius:15px;
    border:4px solid @mainColorDark;
    img{
        display:block;
    }
}

.productInfo{
    flex-basis:auto;
    flex-grow:1;
    padding:10px;
}

.productName{
    font-weight:bold;
    font-size:16px;
}