.component {
    background-color: #FF494B;
    display: flex;
    outline: 1px solid red;
    justify-content: center;
    align-items: center;
    height: 100vh;

    > div {
        position: relative;
        top: -120px;

        img {
            display: block;
            margin: 0 auto;
        }

        input {
            border-radius: 6px;
            width: 100%;
            box-sizing: border-box;
            background-color: #eee;
            border: none;
            padding: 10px 5px;
            text-align: center;
            font-size: 20px;
            margin-bottom: 10px;

            &:focus {
                outline: none;
            }
        }

        button {
            border-radius: 6px;
            width: 100%;
            box-sizing: border-box;
            background-color: #eee;
            border: none;
            padding: 5px;
            text-align: center;
            font-size: 22px;
            margin-top: 20px;
            color: #353535;

            &:focus {
                background-color: #ccc;
            }
        }
    }
}

.passContainer {
    position: relative;

    i {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 22px;
    }
}
