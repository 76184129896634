@import url(../../shared/colors.less);

.fieldset {
    margin: 15px 0;
    border: 1px solid #999;
    padding: 10px;

    legend {
        padding: 0 5px;
    }

    > div {
        padding-left: 20px;
        margin: 15px 0;
    }
}